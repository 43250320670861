import { Button, Stack } from '@chakra-ui/react'
import { zodResolver } from '@hookform/resolvers/zod'
import * as React from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { TextInput } from '../../../components/FormElements'
import HomePageLayout from '../../../containers/HomePageLayout'
import { loginDefaultValues, loginSchema } from '../../../utils'

type Props = {
  landingImage: string
  logo: string
}

function Login({ landingImage, logo }: Props): React.ReactElement {
  const methods = useForm({
    defaultValues: loginDefaultValues,
    resolver: zodResolver(loginSchema)
  })

  return (
    <HomePageLayout
      landingImage={landingImage}
      logo={logo}
      title="Log In"
      subTitle="Login to your account"
      contentPosition="center"
    >
      <FormProvider {...methods}>
        <Stack spacing={4} color="brand.200">
          <TextInput
            data-testid="login-id-text-input"
            label="ID or Passport Number"
            name="idNumber"
            type="text"
            color="brand.800"
          />
          <Stack spacing={6}>
            <Button data-testid="login-submit-button" loadingText="" size="lg" id="login-button">
              Get OTP
            </Button>
          </Stack>
        </Stack>
      </FormProvider>
    </HomePageLayout>
  )
}

export default Login
