import { AppContent } from '../components/AppContent'
import { ErrorBoundary } from '../layouts'
import Navigation from './components/navigation'
import theme from '../theme'
import images from '../theme/images'

function AudiApp() {
  const baseURL = import.meta.env.VITE_ADMIN_BASE_URL
  return (
    <AppContent theme={theme} baseURL={baseURL ?? '/'} appName="admin">
      <ErrorBoundary>
        <Navigation
          logo={images.vwfsLogo}
          landingImage={images.vwLoginImg}
          baseURL={baseURL ?? '/'}
          title="ADMIN"
        />
      </ErrorBoundary>
    </AppContent>
  )
}

export default AudiApp
