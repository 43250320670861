import React from 'react'
import ReactDOM from 'react-dom/client'
import App from '../src/admin/App'
import RootApp from '../src/RootApp'
import theme from '../src/theme'
import '../src/theme/fonts.css'

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
root.render(
  <RootApp font={theme.fonts.body}>
    <App />
  </RootApp>
)
