import { Route, Routes } from 'react-router-dom'
import { Navbar } from '../../../components'
import { NotFoundScreen } from '../../../containers'
import { RequireAuth } from '../../../context/AuthProvider'
import SessionProvider from '../../../context/SessionProvider'
import { PageWrap } from '../../../layouts'
import { Login } from '../../containers'

/**
 * Trying to keep navigation as simple as possible. Easier to comprehend as a new developer.
 * This will still be expanded on greatly. Perhaps lazy imports for Screens for performance 🚀
 * @returns
 */

type Props = {
  landingImage: string
  logo: string
  baseURL: string
  title: string
}

function Navigation({ landingImage, logo, baseURL, title }: Props) {
  return (
    <Routes>
      {/*
       * Unauthenticated pages
       */}
      <Route>
        <Route element={<PageWrap title={title} />}>
          <Route path={`${baseURL}`} element={<Login landingImage={landingImage} logo={logo} />} />
          <Route path="*" element={<NotFoundScreen />} />
        </Route>
      </Route>
      {/*
       * Authenticated pages
       *
       * All paths will be prepended with /auth and are protected by the AuthProvider
       * When a Guest tries to navigate to any of these, they will be taken to /
       */}
      <Route
        element={
          <RequireAuth baseURL={`${baseURL}`}>
            <Navbar logo={logo} />
          </RequireAuth>
        }
      >
        <Route
          path={`${baseURL}auth`}
          element={
            <SessionProvider>
              <PageWrap title={title} />
            </SessionProvider>
          }
        >
          <Route path="*" element={<NotFoundScreen />} />
        </Route>
      </Route>
    </Routes>
  )
}

export default Navigation
